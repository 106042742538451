dialog {
    position: absolute;
    left: 0; right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin: auto;
    border: solid;
    padding: 1em;
    background: white;
    color: black;
    display: block;
}

dialog:not([open]) {
    display: none;
}

dialog + .backdrop {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0,0,0,0.1);
}

._dialog_overlay {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
}

dialog.fixed {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
}
